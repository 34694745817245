
import {
  defineComponent,
  ref
} from 'vue'
import { useRouter } from 'vue-router'
import Dashboard from '@/components/Dashboard.vue'
import StopSearch from '@/components/StopSearch.vue'
import Modal from '@/components/Modal.vue'
import GenericError from '@/components/GenericError.vue'
import StopsIcon from '@/components/vectors/StopsIcon.vue'
import GenericPage from '@/views/GenericPage.vue'
import {
  RTPIBusStop,
  BusStop
} from '@/models/BusStop'
import useBasket from '@/composables/useBasket'
import { getRTPIBusStops } from '@/expressway-api/busStops.api'

export default defineComponent({
  components: { Dashboard, StopSearch, Modal, GenericError, StopsIcon, GenericPage },
  setup () {
    const router = useRouter()
    const { showBootMessage, clearBasket } = useBasket()
    const stops = ref<BusStop[]>()

    getRTPIBusStops()
      .then((response: RTPIBusStop[]) => {
        stops.value = response
          .sort((a: RTPIBusStop, b: RTPIBusStop) => {
            if (a.City > b.City) {
              return 1
            }

            return -1
          })
          .map((busStop: RTPIBusStop) => ({
            CityId: 0,
            CityName: busStop.City,
            BusStopId: parseInt(busStop.UTrackId),
            BusStopName: busStop.PlatformName,
            IsWheelchairAccessible: busStop.WheelchairAccessible,
            BusStopShownNumber: parseInt(busStop.UTrackId)
          }))
      })

    const onSelect = (destination: BusStop) => {
      router.push({
        name: 'rtpi Departures List',
        params:
          { stopId: destination.BusStopId, props: JSON.stringify({ destination }) }
      })
    }

    return {
      onSelect,
      showBootMessage,
      clearBasket,
      stops
    }
  }
})
