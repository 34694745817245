
import { defineComponent, PropType, computed, ref } from 'vue'
import ChevronRightIcon from '@/components/vectors/ChevronRight.vue'
import { RTPIDeparture } from '@/models/RtpiDeparture'
import dayjs from 'dayjs'
import { BusStop } from '@/models/BusStop'

export default defineComponent({
  components: {
    ChevronRightIcon
  },
  props: {
    stopId: {
      type: String,
      required: true
    },
    departure: {
      type: Object as PropType<RTPIDeparture>,
      required: true
    },
    stopName: {
      type: String,
      required: true
    }
  },
  // eslint-disable-next-line max-lines-per-function
  setup (props) {
    /**
     *  dayjs diff = miliseconds
     *  unix = seconds
     *  new Date() = miliseconds
     *  getTime() = miliseconds
     *  getTimezoneOffset() = minutes
     */
    const offsetUnix = new Date().getTimezoneOffset() * 60 // offset in seconds - unix
    const now = ref() // now in miliseconds
    const nowUnix = ref() // now in seconds unix
    const nowdDayjsObj = ref()
    const nowFormatedDebug = ref()
    const parseTime = (time: string): string => time?.split(' ')[1].slice(0, 5)
    const parseDate = (time: string): string => time.split(' ')[0]

    const updateTiming = () => {
      now.value = (new Date().getTime()) + (offsetUnix * 1000) // now in miliseconds
      nowUnix.value = now.value / 1000 // now in seconds unix
      nowdDayjsObj.value = dayjs.unix(nowUnix.value)
      nowFormatedDebug.value = nowdDayjsObj.value.format()
    }
    updateTiming()
    setInterval(() => {
      updateTiming()
    }, 1000)
    const busHasDeparted = computed(() => {
      const departureTimeUnix = props.departure.Stop.LiveDepartureTimeUnix ||
        props.departure.Stop.EstimatedDepartureTimeUnix
      if (departureTimeUnix) {
        const departureTime = dayjs.unix(departureTimeUnix + offsetUnix)
        const timeToDepart = departureTime.diff(nowdDayjsObj.value) / 60000
        const busHasDeparted = timeToDepart <= 0
        return busHasDeparted
      }
      return false
    })

    // return the delay in minutes
    const delay = computed(() => {
      const departureTimeUnix = props.departure.Stop.LiveDepartureTimeUnix ||
        props.departure.Stop.EstimatedDepartureTimeUnix

      if (!departureTimeUnix) return null

      const departureTime = dayjs.unix(departureTimeUnix + offsetUnix)
      const scheduledDeparteTime =
            dayjs.unix(props.departure.Stop.ScheduledDepartureTimeUnix + offsetUnix)
      const delay = departureTime.diff(scheduledDeparteTime) / 60000
      return delay // value in minutes
    })

    // miliseconds
    // eslint-disable-next-line complexity
    const timeToDepartureCurrentStop = computed(() => {
      let time
      const stop = props.departure.Stop
      if (stop.LiveArrivalTimeUnix) {
        time = stop.LiveArrivalTimeUnix
      } else if (stop.EstimatedDepartureTimeUnix) {
        time = stop.EstimatedDepartureTimeUnix
      } else if (stop.ScheduledDepartureTimeUnix) {
        time = stop.ScheduledDepartureTimeUnix
      } else {
        return null
      }
      const localTime = (time + offsetUnix) * 1000
      // return time
      if ((localTime - now.value) < 0) {
        return null
      }
      const difference = new Date(localTime - now.value)
      return difference
    })

    // miliseconds
    const timeToDepartureOriginStopMinutes = computed(
      () => timeToDepartureOriginStop.value.getTime() / 60000)
    const timeToDepartureOriginStop = computed(() => {
      const trip = props.departure.Trip
      const time = (trip.DepartureTimeUnix + offsetUnix) * 1000
      const difference = new Date(time - now.value)
      return difference
    })

    // eslint-disable-next-line complexity
    const busStatus = computed(() => {
      if (busHasDeparted.value) {
        if (props.departure.Stop.LiveDepartureTimeFormattedLocal) {
          return `Departed at ${parseTime(props.departure.Stop.LiveDepartureTimeFormattedLocal)}`
        }
        if (props.departure.Stop.EstimatedDepartureTimeFormattedLocal) {
          return `Estimated Dep 
            ${parseTime(props.departure.Stop.EstimatedDepartureTimeFormattedLocal)}`
        }
        if (props.departure.Stop.ScheduledDepartureTimeFormattedLocal) {
          return `Estimated Dep 
            ${parseTime(props.departure.Stop.ScheduledDepartureTimeFormattedLocal)}`
        }
        return 'Scheduled Dep'
      } else {
        if (supportingStatus.value.text === 'Early') {
          if (props.departure.Stop.LiveDepartureTimeFormattedLocal) {
            return `Estimated Dep
              ${parseTime(props.departure.Stop.LiveDepartureTimeFormattedLocal)}`
          }
          if (props.departure.Stop.EstimatedDepartureTimeFormattedLocal) {
            return `Estimated Dep
              ${parseTime(props.departure.Stop.EstimatedDepartureTimeFormattedLocal)}`
          }
          // this case should not happen because the bus is early
          return 'Scheduled'
        }

        if (delay.value !== null && delay.value < 2) {
          return 'Scheduled'
        }

        // delayed but we have estimated
        if (props.departure.Stop.EstimatedDepartureTimeFormattedLocal) {
          return `Estimated Dep
            ${parseTime(props.departure.Stop.EstimatedDepartureTimeFormattedLocal)}`
        }
      }
      // we dont have information
      return 'Scheduled'
    })

    // eslint-disable-next-line complexity
    const supportingStatus = computed(() => {
      // bus departed nothing to show
      if (busHasDeparted.value) {
        return {
          type: null,
          text: null
        }
      }

      // EARLY DEPARTURE! = the bus is departing at least 1 minute earlier than scheduled
      if (delay.value !== null && delay.value < -1) {
        return {
          type: 'default',
          text: 'Early'
        }
      }
      // 1 - estimated or live departure time
      // if not delay.value is because we dont know anything about the bus
      if (!busHasDeparted.value && delay.value !== null) {
        // SCHEDULED
        if (delay.value >= -1 && delay.value <= 2) {
          return {
            type: 'scheduled',
            text: 'On Time'
          }
        }
        // DELAYED
        if (delay.value > 5) {
          return {
            type: 'delayed',
            text: 'Delayed'
          }
        }
      }
      return {
        type: null,
        text: null
      }
    })

    // eslint-disable-next-line complexity
    const timeToDepartureCurrentStopString = (timeToDeparture: Date): string => {
      if (busHasDeparted.value) {
        return 'Departed'
      }
      if (!timeToDeparture) {
        return ''
      }
      let message = ''

      const hours = timeToDeparture.getHours() - 1
      const minutes = timeToDeparture.getMinutes()

      if (hours === 1) {
        message += `${hours} hour `
      } else if (hours > 0) {
        message += `${hours} hours `
      }

      if (minutes > 0) {
        message += `${minutes} mins`
      }

      if (hours <= 0 && minutes <= 0) {
        message = 'Due'
      }

      return message
    }

    const departureLink = () => (
      {
        name: 'Track Departure',
        params: {
          date: parseDate(props.departure.Trip.DepartureTimeFormattedLocal),
          serviceNumber: props.departure.Trip.RouteId,
          time: parseTime(props.departure.Trip.DepartureTimeFormattedLocal).replaceAll(':', ''),
          direction: props.departure.Trip.Direction,
          stopIds: props.stopId
        }
      }
    )

    const origin = {
      BusStopName: props.stopName
      // TODO - waiting for stop id
    } as BusStop

    const destination = {
      BusStopName: props.departure.Trip.ArrivalLocationName
      // TODO - waiting for stop id
    } as BusStop

    const bookNowLinkForDeparture = () => (
      {
        name: 'Journey Search',
        params: {
          props: JSON.stringify({
            oneWay: true,
            destination,
            origin,
            departureDate: dayjs().format('YYYY-MM-DD')
          })
        }
      }
    )

    const timezoneOffset = computed(() => `${new Date().getTimezoneOffset()} minutes`)
    return {
      timezoneOffset,
      nowFormatedDebug,
      offsetUnix,
      delay,
      busHasDeparted,
      now,
      busStatus,
      supportingStatus,
      parseTime,
      timeToDepartureCurrentStop,
      departureLink,
      timeToDepartureCurrentStopString,
      bookNowLinkForDeparture,
      timeToDepartureOriginStopMinutes,
      timeToDepartureOriginStop
    }
  }
})
