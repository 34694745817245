
import GmapCustomMarker from 'vue2-gmap-custom-marker'
import {
  computed,
  defineComponent,
  PropType,
  ref,
  onMounted
} from 'vue'
import { MapPoint, MapStop, RTPIActiveVehicle } from '@/models/RtpiDeparture'
import BusIcon from '@/components/vectors/BusIconRTPI.vue'
import StopIconMap from './Icons/StopIconMap.vue'
import DepartureIconMap from '@/components/RealTime/Icons/DepartureIconMap.vue'
import EndpointIconMap from '@/components/RealTime/Icons/EndpointIconMap.vue'
import CurrentLocationIcon from '@/components/RealTime/Icons/CurrentLocationIcon.vue'
import MapZoomIn from '@/components/RealTime/Icons/MapZoomIn.vue'
import MapZoomOut from '@/components/RealTime/Icons/MapZoomOut.vue'
import debounce from 'lodash.debounce'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const calculateRadius = (bounds: any) => {
  const r = 6378.8
  // degrees to radians (divide by 57.2958)
  const neLat = bounds.getNorthEast().lat() / 57.2958
  const neLng = bounds.getNorthEast().lng() / 57.2958
  const cLat = bounds.getCenter().lat() / 57.2958
  const cLng = bounds.getCenter().lng() / 57.2958
  // distance = circle radius from center to Northeast corner of bounds
  const rKm = r * Math.acos(
    Math.sin(cLat) * Math.sin(neLat) +
    Math.cos(cLat) * Math.cos(neLat) * Math.cos(neLng - cLng)
  )
  return rKm * 1000 // radius in meters
}
interface MapEvent {
  lat(): number;
  lng(): number;
}
const travelledPolylinesOptions = {
  strokeOpacity: 4,
  strokeWeight: 5,
  strokeColor: '#C2002F',
  icons: [{
    icon: 'lineSymbol',
    offset: '205px',
    repeat: '100px'
  }]
}
const toTravellPolylinesOptions = {
  strokeOpacity: 4,
  strokeWeight: 5,
  strokeColor: '#fbcad4',
  icons: [{
    icon: 'lineSymbol',
    offset: '205px',
    repeat: '100px'
  }]
}
const options = {
  fullscreenControl: false,
  zoomControl: false,
  streetViewControl: false,
  styles: [
    {
      featureType: 'poi.business',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'poi.medical',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'poi.park',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'poi.school',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'poi.place_of_worship',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'poi.government',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'transit',
      elementType: 'labels.icon',
      stylers: [{ visibility: 'off' }]
    }
  ]
}
export default defineComponent({
  components: {
    GmapCustomMarker,
    BusIcon,
    StopIconMap,
    DepartureIconMap,
    EndpointIconMap,
    CurrentLocationIcon,
    MapZoomOut,
    MapZoomIn
  },
  props: {
    formatStops: {
      type: Array as PropType<MapStop[]>,
      required: true
    },
    activeVehicle: {
      type: Object as PropType<RTPIActiveVehicle>,
      default: {} as RTPIActiveVehicle
    },
    travelledPolylines: {
      type: Array,
      default: () => []
    },
    toTravellPolylines: {
      type: Array,
      default: () => []
    },
    selectedStop: {
      type: Object as PropType<MapStop | undefined>,
      default: () => undefined
    },
    centerOfMap: {
      type: Object as PropType<MapPoint | undefined>,
      default: undefined
    },
    userLocation: {
      type: Object as PropType<MapPoint | undefined>,
      default: undefined
    },
    showStopLabel: { // should this say or should I add the name back?
      type: String,
      required: false
    },
    zoom: {
      type: Number,
      default: 15
    },
    useEndpointIcon: {
      type: Boolean,
      default: false
    }
  },
  emits: ['setSelectedBusStop', 'update:centerOfMap', 'update:zoom', 'newRadius'],
  setup (props, { emit }) {
    const showMap = ref(false)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const map = ref<any>()
    const setSelectedBusStop = (selectedStop: MapStop) => {
      emit('setSelectedBusStop', selectedStop)
    }
    const activeVehicleTransformed = computed(() => {
      if (props.activeVehicle) {
        return {
          StartPoint: false,
          EndPoint: false,
          Position: {
            lat: props.activeVehicle.CurrentWgs84LatitudeDegrees,
            lng: props.activeVehicle.CurrentWgs84LongitudeDegrees
          }
        } as MapStop
      }
      return {} as MapStop
    })
    onMounted(() => {
      showMap.value = true
      updateRadius()
    })
    const zoomChanged = (zoom: number) => {
      emit('update:zoom', zoom)
      updateRadius()
    }
    const updateCenterOfMap = (center: MapEvent) => {
      const newCenter = {
        lat: center.lat(),
        lng: center.lng()
      } as MapPoint
      emit('update:centerOfMap', newCenter)
    }
    const updateRadius = () => {
      if (map.value) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const bounds = map.value.$mapObject.getBounds()
        const newRadius = calculateRadius(bounds)
        emit('newRadius', newRadius)
      }
    }
    const centerChanged = debounce(updateCenterOfMap, 100)
    return {
      map,
      zoomChanged,
      centerChanged,
      setSelectedBusStop,
      travelledPolylinesOptions,
      toTravellPolylinesOptions,
      options,
      activeVehicleTransformed,
      showMap
    }
  }
})
