
import {
  computed,
  defineComponent,
  ref
} from 'vue'
import PageHeader from '@/components/PageHeader.vue'
import DepartureItem from '@/components/RealTime/DepartureItem.vue'
import {
  RTPIBusStop
} from '@/models/BusStop'
import {
  RTPIDeparture
} from '@/models/RtpiDeparture'
import {
  getRTPIBusStopById,
  getRTPIDeparturesByStop
} from '@/expressway-api/busStops.api'
import ArrowDown from '@/components/vectors/ArrowDown.vue'
import Spinner from '@/components/Spinner.vue'

export default defineComponent({
  components: {
    PageHeader,
    DepartureItem,
    ArrowDown,
    Spinner
  },
  props: {
    stopId: {
      required: true,
      type: String
    }
  },
  setup (props) {
    const stop = ref<RTPIBusStop>()
    const departures = ref<RTPIDeparture[]>([])
    const heading = ref('')
    const today = new Date()
    const tomorrow = new Date(today)
    const pageQuantity = 6
    const indexActiveDepartures = ref(pageQuantity)
    const loading = ref(true)
    const stopName = ref('')
    today.setMinutes(today.getMinutes() - 45)
    tomorrow.setDate(tomorrow.getDate() + 1)
    tomorrow.setHours(4)
    tomorrow.setMinutes(30)
    getRTPIBusStopById(parseInt(props.stopId)).then((stopById: RTPIBusStop) => {
      if (stopById) {
        stop.value = stopById
        heading.value = `${stopById.UTrackId} - ${stopById.PlatformName}`
        stopName.value = stopById.StopName
      }
    })
    getRTPIDeparturesByStop(
      parseInt(props.stopId),
      (today.getTime() / 1000).toFixed(),
      (tomorrow.getTime() / 1000).toFixed()
    ).then((rtpiDepartures: RTPIDeparture[]) => {
      departures.value = orderedDepartures(rtpiDepartures)
      loading.value = false
    })

    const orderedDepartures = (departures: RTPIDeparture[]): RTPIDeparture[] =>
      departures.sort((prev, next) =>
        prev.Stop.ScheduledDepartureTimeUnix - next.Stop.ScheduledDepartureTimeUnix)

    const paginatedDepartures = computed(() =>
      departures.value.slice(0, indexActiveDepartures.value)
    )

    const nextActiveDepartures = () => {
      const offset = departures.value.length - indexActiveDepartures.value
      const sumToIndex = offset >= pageQuantity ? pageQuantity : offset
      indexActiveDepartures.value = indexActiveDepartures.value + sumToIndex
    }

    return {
      stop,
      paginatedDepartures,
      heading,
      departures,
      nextActiveDepartures,
      loading,
      stopName
    }
  }
})
