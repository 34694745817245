
import {
  defineComponent,
  ref,
  PropType
} from 'vue'
import {
  RTPIDeparture,
  RTPIStop,
  MapStop
} from '@/models/RtpiDeparture'
import BusIcon from '@/components/vectors/BusIconRTPI.vue'

export default defineComponent({
  props: {
    departure: {
      type: Object as PropType<RTPIDeparture>,
      required: true
    },
    selectedStop: {
      type: Object as PropType<MapStop | undefined>,
      default: () => undefined
    }
  },
  emits: ['markStop'],
  setup (props, { emit }) {
    const activeStop = ref<RTPIStop>()
    const parseTime = (time: string): string => time?.split(' ')[1].slice(0, 5)
    const markStop = (stop: RTPIStop) => {
      activeStop.value = stop
      emit('markStop', activeStop.value)
    }
    // eslint-disable-next-line complexity
    const stopClass = (stop: RTPIStop): string => {
      if (
        props.departure.Tracking.IsMovingToStopSequence === stop.Sequence ||
        props.departure.Tracking.IsArrivedAtStopSequence === stop.Sequence
      ) {
        return 'bus-stop'
      } else if (
        props.departure.Tracking.IsArrivedAtStopSequence > stop.Sequence ||
        props.departure.Tracking.IsMovingToStopSequence > stop.Sequence) {
        return 'completed'
      } else {
        return 'pending'
      }
    }
    const showBus = (stop: RTPIStop): string => {
      if (stopClass(stop) === 'bus-stop') {
        return 'going-to-stop'
      }
      if (props.departure.Tracking.IsArrivedAtStopSequence === stop.Sequence) {
        return 'on-stop'
      }
      return ''
    }

    const stopDepartureTime = (stop: RTPIStop): string => {
      if (stop.LiveDepartureTimeFormattedLocal) {
        const time = parseTime(stop.LiveDepartureTimeFormattedLocal)
        return `Dep ${time}`
      } else if (stop.EstimatedDepartureTimeFormattedLocal) {
        const time = parseTime(stop.EstimatedDepartureTimeFormattedLocal)
        return `Exp ${time}`
      } else if (stop.ScheduledDepartureTimeFormattedLocal) {
        return 'Scheduled'
      }
      return ''
    }
    return {
      showBus,
      parseTime,
      markStop,
      stopClass,
      stopDepartureTime,
      activeStop
    }
  },
  components: { BusIcon }
})
